export const FirebaseConfig = {
	"projectId": "hiilla-go-8c725",
	"appId": "1:690402735269:web:852259a564872e9d71094c",
	"databaseURL": "https://hiilla-go-8c725-default-rtdb.firebaseio.com",
	"storageBucket": "hiilla-go-8c725.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAF6JaGze3oJdvf4sjG-4V5CYepXQLyZrY",
	"authDomain": "hiilla-go-8c725.firebaseapp.com",
	"messagingSenderId": "690402735269",
	"measurementId": "G-GECBHQSQ8W"
};